@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  line-height: 32px;
  background-color: rgb(255, 255, 255);
  color: rgb(87 85 85);
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(51, 51, 51);
    color: rgb(237, 237, 237);
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4 {
  line-height: 32px;
  color: #001935;
}

[data-theme="dark"] h1,
[data-theme="dark"] h2,
[data-theme="dark"] h3,
[data-theme="dark"] h4 {
  color: rgb(237, 237, 237);
}

.dark {
  background-color: rgb(51, 51, 51);
  color: rgb(237, 237, 237);
}

.light {
  background-color: rgb(255, 255, 255);
  color: rgb(87 85 85);
}

.break-word {
  word-wrap: normal;
  word-break: break-all;
  white-space: normal;
  display: block;
  height: auto;
  line-height: 1.4;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* TOGGLE SWITCH CSS */
.toggle-switch:checked {
  background-color: #fff;
}

/* .toggle-switch:checked ~ span:last-child {
  --tw-translate-x: 1.75rem;
} */
.toggle-switch:checked ~ span:last-child {
  --tw-translate-x: 2.3rem;
}
